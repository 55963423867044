import React from "react"
import { CtaCardContainer, CtaCard } from "./cta_cards"
import Section from "./section"
import Label from "./label"
import { useTranslations } from "../utils/label_translations_hook"

const RelatedContent = props => {
  const moreLabel = useTranslations("find_out_more")

  if (!props.blok.content_item_1 && !props.blok.content_item_2) {
    return null
  }

  if (
    !props.blok.content_item_1.content &&
    !props.blok.content_item_2.content
  ) {
    return null
  }

  const content_items = [
    props.blok.content_item_1 && props.blok.content_item_1,
    props.blok.content_item_2 && props.blok.content_item_2,
  ]

  if (content_items.length > 0) {
    return (
      <Section variant="cardSection">
        <Label variant="bodyHeading">
          {props.blok.title || "more_donkervoort"}
        </Label>
        {content_items && (
          <CtaCardContainer>
            {content_items.map(
              blok =>
                blok &&
                blok.content && (
                  <CtaCard
                    key={blok.content._uid}
                    blok={{
                      title: blok.content.card_short_title
                        ? blok.content.card_short_title
                        : blok.content.title,
                      image:
                        blok.content.card_image &&
                        blok.content.card_image.filename
                          ? blok.content.card_image
                          : blok.content.main_image,
                      button_text: blok.content.card_button_text
                        ? blok.content.card_button_text
                        : moreLabel,
                      link: {
                        linktype: "story",
                        story: { full_slug: blok.full_slug },
                      },
                    }}
                  />
                )
            )}
          </CtaCardContainer>
        )}
      </Section>
    )
  } else {
    return null
  }
}

export default RelatedContent
